.button,
button {
  font-weight: bolder;
  font-size: 1.3em;
  border-radius: 5px;
}

h1,
h2,
h3,
h4,
h5 {
  color: $secondary-color;

  span {
    color: $primary-color;
  }
}

p,
ul {
  font-size: 1.3em;
  line-height: 2em;
}

body {
  background: $secondary-color;
}

.grid-x {
  max-width: 1400px;
  margin: auto;
}

.primary-content {
  max-width: 2000px;
  margin: auto;
  background: #FFF;
}

section {
  .alt {
    background-color: $bg-color;
  }
}

.page-contain {
  min-height: 50vh;
}

blockquote {
  font-size: 1em;

  font-style: italic;
  padding: 10px 20px;
  border-left: 5px solid $primary-color;
  margin: 20px 0;

  p {
    color: $secondary-color;
  }
}

//Semantic Styles

.float-left {
  float: left;
}

.float-right {
  float: right;
}

@mixin down-x ($x) {
  padding-top: $x;
}

@mixin spread-x ($x) {
  padding-right: $x;
  padding-left: $x;
}

@mixin bot-x ($x) {
  padding-bottom: $x;
}

@mixin pad-xy ($x, $y) {
  padding: $y $x;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pad-half {
  padding: 0.5em;
}

.pad-1 {
  padding: 1em;
}

.pad-2 {
  padding: 2em;
}

.pad-3 {
  padding: 3em;
}

/* Bottom*/
.bot-half {
  padding-bottom: 0.5em;
}

.bot-1 {
  padding-bottom: 1em;
}

.bot-2 {
  padding-bottom: 2em;
}

.bot-3 {
  padding-bottom: 3em;
}

/* Spread*/
.spread-1 {
  padding-right: 1em;
  padding-left: 1em;
}

.spread-2 {
  padding-right: 2em;
  padding-left: 2em;
}

.spread-3 {
  padding-right: 3em;
  padding-left: 3em;
}

/* Down*/
.down-1 {
  padding-top: 1em;
}

.down-2 {
  padding-top: 2em;
}

.down-3 {
  padding-top: 3em;
}

.down-half {
  padding-top: 0.5em;
}

.list-style-none {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

//Angled Edges
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {

  // Possible values for $pseudo are: before, after, both
  @if $pseudo =='before' or $pseudo =='after' or $pseudo =='both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo =='both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo =='before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo =='after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        }

        @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo =='both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}