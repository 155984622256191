.intro {
  .intro-frame {
    display: flex;
    height: 800px;
    width: 100%;

    img {
      object-fit: cover;
      object-position: bottom center;
      min-width: 100%;
      max-height: 100%;
    }
  }
}

.events-media {
  h2 {
    margin-bottom: 15px;
  }
  

  .event-list, .post-list {
    list-style: none;
    margin: 0;

    .nothing-here {
      display: block;
      color: transparentize($color: #000000, $amount: .7);
      line-height: 1.3;
      font-size: 1.1em;
      border: 1px solid transparentize($color: #979797, $amount: .8);
      padding: 10px 20px;
      text-align: center;

    }

    li {
      a {
        display: block;
        padding: 10px 20px;
        background: #FFF;
        border: 1px solid #979797;
        margin-bottom: 15px;
        transition: .3s all ease-out;

      &:hover {
        transform: translateY(-4px);
        border-color: $primary-color;
      }

      h4 {
        line-height: 1.3em;
        margin: 0;
        padding: 0;
      }

      span {
        color: $primary-color;
        font-weight: bolder;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
  }
}

.about-justin {
  position: relative;
  overflow: hidden;

    .about-frame {
      position: absolute;
      pointer-events: none;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 0;
      justify-content: flex-end;

      img {
        margin-right: 150px;
        align-self: flex-end;
        height: 90%;
      }
    }
}

@include breakpoint (medium down) {
  .short-about {
    text-align: center;
  }

  .events-media {
    text-align: center;
    h2 {
      font-size: 1.5em;
    }
  }
}