.media-post {
  margin-top: 30px;

  a {
    h3 {
      display: inline;
      border-bottom: 4px solid transparentize($color: $secondary-color, $amount: .9);
      transition: .5s all ease-out;
    }

    &:hover h3 {
      color: $primary-color;
      border-bottom-color: $primary-color;
    }
  }

  .date-author {
    display: block;
    font-size: 1.3em;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


.post-full {

  h1 {
    line-height: 1.3em;
  }
  .date-author {
    display: block;
    font-size: 1.3em;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}