.contact-form {
  background: #FFF;


  .upsells {
    font-size: 1.2em;
    svg {
      color: $primary-color;
    }
  }
  label {
    font-weight: bolder;
    font-size: 1em;
  }
  input, select, textarea {
    background: transparentize($secondary-color, .8);
    box-shadow: none;
    border: 2px solid $secondary-color;
    &:active, &:focus {
      background: #FFF;
      box-shadow: none;
      border: 2px solid $secondary-color;
    }
  }

  select {
    margin-bottom: 0;
  }

  .button {
    width:100%;
    font-weight: bolder;
    font-size: 1.1em;
  }
}

.thankyou {
  padding-bottom: 20vh;
}

.contact-info {
  background: transparentize($secondary-color, 0.8);
  border: 2px solid $secondary-color;
  border-radius: 5px;
  padding: 20px 30px;
  line-height: 1.4em;
}