.event {
  margin-top: 30px;

  .event-frame {
    display: flex;
    width: 100%;
    height: auto;

    img {
      object-fit: cover;
      object-position: center;
      min-width: 100%;
      max-height: 100%;
    }
  }

  a {
    h3 {
      display: inline;
      border-bottom: 4px solid transparentize($color: $secondary-color, $amount: .9);
      transition: .5s all ease-out;
    }

    &:hover h3 {
      color: $primary-color;
      border-bottom-color: $primary-color;
    }
  }

  .date-only {
    display: block;
    font-size: 1.3em;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


.event-full {

  h1 {
    line-height: 1.3em;
  }

  .event-frame {
    img {
      display: block;
      margin: auto;
    }
  }

  .date-author {
    display: block;
    font-size: 1.3em;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sponsor-level {
    border: 1px solid darken(#f6f4f9, 10%);
    border-radius: 5px;
    background: #f6f4f9;
    margin-bottom: 40px;
    padding: 30px 20px;

    .button {
      width: 100%;
      margin-top: 10px
    }
  }
}

.event-form {
  max-width: 700px;
  border: 1px solid #efefef;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}

.schedule ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0 0;

  li {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #efefef;
    border-radius: 5px;
    background: #f6f4f9;
  }
}