.header-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1600px;
  margin: auto;

  .nav-icon {
    margin: 1em;
    width: 60px;
    top: 10px;
    right: 0;
    z-index: 999;
    background: $secondary-color;
    padding: 5px 10px;
    position: fixed;
    display: none;
  }
  
  .nav-icon:after, 
  .nav-icon:before, 
  .nav-icon div {
    background-color: #FFF;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

  .logo {
    padding: 20px;
    height: 200px;
  }
  ul {
    padding: 30px 50px;
    list-style: none;
    margin: 0;
    float: right;
    li {
      display: inline-block;

      &.home {
        display: none;
      }
      a {
        font-weight: bolder;
        color: #FFF;
        font-size: 1.1em;
        padding: 0 12px;
        transition: .5s all ease-out;

        &:hover {
          color: $primary-color;
        }
      }

      &.cta {
        a {
          background: $primary-color;
          padding: 10px 20px;
          border-radius: 5px;
          transition: .5s all ease-out;

          &:hover {
            color: #FFF;
            background: darken($color: $primary-color, $amount: 10%);
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  padding-top: 200px;
  background: #FFF;

  &.bg-color {
    background-color: $bg-color;
  }
  
  .footer-frame {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 150px;
    z-index: 0;


    svg{
      align-self: flex-end;
      object-fit: cover;
      min-width: 100%;
    }
  }

  p {
    position: relative;
    z-index: 1;
    color: #FFF;
    background: $secondary-color;
    font-weight: bolder;
    font-size: 1.3em;
    line-height: 5em;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}
@include breakpoint (medium down) {
  .header-nav {
    .logo {
      height: 100px;
    }

    .nav-icon {
      pointer-events: all;
      display: block;
    }

    &.open  {
      ul {
      transform: translateX(0);
    }
    .nav-icon:before {
      transform: translateY(12px) rotate(135deg);
    }
    
    .nav-icon:after {
      transform: translateY(-12px) rotate(-135deg);
    }
    
    .nav-icon div {
      transform: scale(0);
    }
  }


    ul {
      width: 80%;
      height: 100vh;
      padding: 0;
      background: #FFF;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 998;
      border-left: 1px solid #979797;
      transform: translateX(100vw);
      transition: .4s all ease-in-out;

      li {
        padding: 5px 15px;
        display: block;

        &.cta a {
          color: #FFF;
        }

        &.home {
          display: block;
        }

        a {
          display: block;
          text-align: center;
          color: $secondary-color;
        }
      }
    }
  }
}