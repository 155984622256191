@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@500;700&display=swap');

$foundation-palette: (
  primary: #DD4B25,
  secondary: #00193B,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;

$bg-color: #F6F4F9;

$footer-height: 90px;
$nav-height: 110px;

$header-font-family: 'Crimson Pro', serif;
$header-font-weight: 800;
$body-font-family: 'Crimson Pro', serif;
